import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookie from "cookie-universal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const cookie = Cookie();
  const [loginup, setLoginup] = useState(true);
  const [loginObject, setLoginObject] = useState({
    email: "",
    password: "",
  });
  const [signupObject, setSignUpObject] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [sendLogin, setSendLogin] = useState(false);
  const [sendSignup, setSendSignup] = useState(false);
  useEffect(() => {
    const funLogin = async () => {
      const result = await axios
        .post(`http://ce.tuvglobalxavier.com/api/auth/login`, loginObject)
        .then((response) => response);
      const data = await result.data;
      if (data.status) {
        cookie.set("access_token", data.access_token);
        toast.success(`${data.message}`, {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          style: { direction: "rtl" },
        });
        navigate("/")
      } else {
        toast.error(`${data.message}`, {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          style: { direction: "rtl" },
        });
      }
    };

    if (sendLogin) {
      try {
        funLogin();
        setSendLogin(false);
      } catch (error) {
        setSendLogin(false);
      }
    }
  }, [cookie, loginObject, sendLogin]);

  useEffect(() => {
    const funsignup = async () => {
      const result = await axios
        .post(`http://ce.tuvglobalxavier.com/api/auth/register`, signupObject)
        .then((response) => response);
      const data = await result.data;
      if (data.successful) {
        toast.success(`${data.message}`, {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          style: { direction: "rtl" },
        });
        setLoginup(true);
      } else {
        if (data.message.email) {
          toast.error(`البريد الالكتروني مستخدم من قبل`, {
            position: "top-right",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            style: { direction: "rtl" },
          });
        } else {
          toast.error(`${data.message.message}`, {
            position: "top-right",
            autoClose: 800,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            style: { direction: "rtl" },
          });
        }
      }
    };

    if (sendSignup) {
      try {
        funsignup();
        setSendSignup(false);
      } catch (error) {
        setSendSignup(false);
      }
    }
  }, [sendSignup, signupObject]);

  const loginHandler = (e) => {
    setLoginObject({ ...loginObject, [e.target.name]: e.target.value });
  };

  const signupHandler = (e) => {
    setSignUpObject({ ...signupObject, [e.target.name]: e.target.value });
  };

  const submitLogin = (e) => {
    e.preventDefault();
    setSendLogin(true);
  };

  const submitSingup = (e) => {
    e.preventDefault();
    setSendSignup(true);
  };

  return (
    <div className=" w-screen h-screen flex justify-center items-center flex-col gap-4">
      <div className=" bg-[#eee] p-3 py-6 w-[330px] flex flex-col gap-6 shadow-[#ddd] shadow-md">
        <div className=" w-32 mx-auto">
          <img className=" w-full h-full" src="./logo.png" alt="" />
        </div>
        {loginup ? (
          <div>
            <form
              className=" flex flex-col gap-4"
              onSubmit={(e) => submitLogin(e)}
            >
              <label>
                <p>Email</p>
                <input
                  type="email"
                  name="email"
                  required
                  className=" w-full py-1 px-2 h-10 outline-none text-[#183884]"
                  placeholder=" Enter your email"
                  onChange={(e) => loginHandler(e)}
                />
              </label>
              <label>
                <p>Password</p>
                <input
                  type="password"
                  name="password"
                  required
                  min={6}
                  className=" w-full py-1 px-2 h-10 outline-none text-[#183884]"
                  placeholder=" Enter your password"
                  onChange={(e) => loginHandler(e)}
                />
              </label>
              <label className=" mx-auto mt-4">
                <input
                  type="submit"
                  value="Login"
                  className=" bg-[#183884] text-white py-2 px-10 cursor-pointer"
                />
              </label>
            </form>
          </div>
        ) : (
          <div>
            <form
              className=" flex flex-col gap-4"
              onSubmit={(e) => submitSingup(e)}
            >
              <label>
                <p>Name</p>
                <input
                  type="text"
                  name="name"
                  required
                  className=" w-full py-1 px-2 h-10 outline-none text-[#183884]"
                  placeholder=" Enter your name"
                  onChange={(e) => signupHandler(e)}
                />
              </label>
              <label>
                <p>Email</p>
                <input
                  type="email"
                  name="email"
                  required
                  className=" w-full py-1 px-2 h-10 outline-none text-[#183884]"
                  placeholder=" Enter your email"
                  onChange={(e) => signupHandler(e)}
                />
              </label>
              <label>
                <p>Password</p>
                <input
                  type="password"
                  name="password"
                  required
                  min={6}
                  className=" w-full py-1 px-2 h-10 outline-none text-[#183884]"
                  placeholder=" Enter your password"
                  onChange={(e) => signupHandler(e)}
                />
              </label>
              <label className=" mx-auto mt-4">
                {loginup ? (
                  <input
                    type="submit"
                    value="Login"
                    className=" bg-[#183884] text-white py-2 px-10 cursor-pointer"
                  />
                ) : (
                  <input
                    type="submit"
                    value="Sign up"
                    className=" bg-[#183884] text-white py-2 px-10 cursor-pointer"
                  />
                )}
              </label>
            </form>
          </div>
        )}
      </div>
      <div className=" bg-[#eee] w-[330px] h-14 flex justify-center items-center">
        {loginup ? (
          <p className=" text-[18px] flex gap-2">
            Don't have an account?
            <span
              className=" text-[#183884] cursor-pointer"
              onClick={() => setLoginup(false)}
            >
              Sign up
            </span>
          </p>
        ) : (
          <p className=" text-[18px] flex gap-2">
            Have an account?
            <span
              className=" text-[#183884] cursor-pointer"
              onClick={() => setLoginup(true)}
            >
              Log in
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default Login;
