import React from "react";
import Search from "./Search";
import Certifcate from "./Certifcate";
import Login from "./Login";
import { Route, Routes } from "react-router-dom";
import WithGaurd from "./WithGaurd";

const App = () => {
  return (
    <div>
    <Routes>
      <Route path="/" element={ <WithGaurd><Certifcate /></WithGaurd>  } />
      <Route path="/login" element={ <Login /> } />
      <Route path="/search" element={ <Search /> } />
    </Routes>
  </div>
  );
}

export default App;