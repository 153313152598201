import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Search = () => {
  const [value, setValue] = useState("");
  const [showbar, setShowbars] = useState(false);
  const [sendSearch, setSendSearch] = useState(false);
  const [available, setAvailable] = useState(5);
  const [expireDate, setExpireDate] = useState(null);

  const searchCert = async (val) => {
    const result = await axios.get(`http://ce.tuvglobalxavier.com/api/certificates/check/${val}`);

    const data = await result.data;
    console.log(data)
    setAvailable(data.available);
    setExpireDate(data.expire_date);
  };

  useEffect(() => {
    if (sendSearch) {
        try {
            searchCert(value)
            setSendSearch(false)
        } catch (error) {
            setSendSearch(false)
        }
    }
  }, [sendSearch]);

  const submit = (e) => {
    e.preventDefault();
    if (value.length >= 1) {
      setSendSearch(true)
    }
  };

  return (
    <div className=" flex flex-col h-screen">
      <div className=" h-[97px] bg-[#3cc0e5] flex items-center gap-10 p-2 relative">
        <div className=" w-32">
          <img className=" w-full h-full" src="./logo.png" alt="" />
        </div>
        <div className=" flex-1 hidden lg:flex">
          <ul className=" flex gap-6">
            <li>
              <a href="https://tuvglobalxavier.com/" className=" text-lg cursor-pointer">Home</a>
            </li>
            <li>
              <a href="https://tuvglobalxavier.com/about-us-2/" className=" text-lg cursor-pointer">About</a>
            </li>
            <li>
              <a href="https://tuvglobalxavier.com/services/" className=" text-lg cursor-pointer">Services</a>
            </li>
            <li>
              <a href="https://tuvglobalxavier.com/partners/" className=" text-lg cursor-pointer">Partners</a>
            </li>
            <li>
              <a href="https://tuvglobalxavier.com/contact-us/" className=" text-lg cursor-pointer">Contact Us</a>
            </li>
            <li>
              <p className=" text-lg cursor-pointer">
                Varification of certificate
              </p>
            </li>
          </ul>
        </div>
        <div className=" lg:hidden text-xl border px-2 py-1 border-black absolute top-[50%] right-3 translate-y-[-50%] cursor-pointer">
          <FontAwesomeIcon icon={faBars} onClick={() => setShowbars((prev) => !prev)} />
        </div>
        <div className="hidden lg:flex">
          <p className=" text-xl">SAFETY IN GOOD HANDS</p>
        </div>
        {
          showbar &&
        <div className=" h-screen w-[330px] bg-white absolute top-0 left-0 shadow-md shadow-[#ddd] lg:hidden">
        <div className=" flex justify-end p-5 text-3xl cursor-pointer">
          <FontAwesomeIcon icon={faXmark} onClick={() => setShowbars(false)} />
        </div>
        <ul className=" flex flex-col gap-6 px-4">
            <li>
              <a href="https://tuvglobalxavier.com/" className=" text-lg cursor-pointer">Home</a>
            </li>
            <li>
              <a href="https://tuvglobalxavier.com/about-us-2/" className=" text-lg cursor-pointer">About</a>
            </li>
            <li>
              <a href="https://tuvglobalxavier.com/services/" className=" text-lg cursor-pointer">Services</a>
            </li>
            <li>
              <a href="https://tuvglobalxavier.com/partners/" className=" text-lg cursor-pointer">Partners</a>
            </li>
            <li>
              <a href="https://tuvglobalxavier.com/contact-us/" className=" text-lg cursor-pointer">Contact Us</a>
            </li>
            <li>
              <Link to="/search" className=" text-lg cursor-pointer">
                Varification of certificate
              </Link>
            </li>
          </ul>
        </div>
      }
      </div>
      <div className=" flex-1 flex flex-col gap-3 justify-center items-center">
        <div>
          <form onSubmit={(e) => submit(e)}>
            <div>
              <input
                type="text"
                placeholder="Enter Certificate Code"
                className=" outline-none rounded-s-sm py-2 px-3 w-[200px] lg:w-[300px] h-12 border border-[#183884] text-[#183884]"
                onChange={(e) => setValue(e.target.value)}
              />
              <input
                type="submit"
                value="Search"
                className=" h-12 bg-[#183884] text-white px-4 lg:px-8 text-lg cursor-pointer hover:opacity-90"
              />
            </div>
          </form>
        </div>
        <p className=" text-lg font-bold">
        {available === true ? <p className=" text-green-700">Valid Certificate Expior on : {expireDate}</p> : (available === false) ? <p className=" text-red-700">Certificate not found</p> : "" }</p>
      </div>
      <div className=" h-[51px] bg-[#183884] flex items-center p-3">
        <p className=" text-lg text-white">Neve | Powered by WordPress</p>
      </div>
    </div>
  );
};
export default Search;
