import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import {
  faArrowRightFromBracket,
  faPenToSquare,
  faPlus,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Cookie from "cookie-universal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Certifcate = () => {
  const navigate = useNavigate()
  const cookie = Cookie();
  const [certiList, setCertiList] = useState([]);
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [deleteCerti, setDeleteCerti] = useState(false);
  const [createCerti, setCreateCerti] = useState({
    certificate_no: "",
    expire_date: "",
  });
  const [sendNewCert, setSendNewCert] = useState(false);
  const [certiId, setCertiId] = useState(null);

  const [updateCerti, setUpdateCerti] = useState({
    id: null,
    certificate_no: "",
    expire_date: "",
  });
  const [sendUpdateCert, setSendUpdateCert] = useState(false);
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    const getCetif = async () => {
      const result = await axios
        .get(`http://ce.tuvglobalxavier.com/api/certificates`, {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        })
        .then((response) => response);

      const data = await result.data;
      setCertiList(data.data);
    };
    try {
      getCetif();
    } catch (error) {}
  }, []);

  useEffect(() => {
    const objectData = async () => {
      const result = await axios.post(
        `http://ce.tuvglobalxavier.com/api/certificates`,createCerti,
        {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        }
      ).then(response => response);

      const data = await result.data;
      setCertiList([...certiList,data])
      toast.success(`تم الاضافة بنجاح`, {
        position: "top-right",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        style: { direction: "rtl" },
      });
      setShow(false);
    };

    if (sendNewCert) {
      try {
        objectData();
        setSendNewCert(false)
      } catch (error) {
        setSendNewCert(false)
      }
    }
  }, [createCerti, sendNewCert]);

  const logoutDash = async() => {
    await axios.post(`http://ce.tuvglobalxavier.com/api/auth/logout`, {
      token: cookie.get("access_token"),
    });
    cookie.remove("access_token")
    navigate("/login", {replace: true});
    toast.success(`تم تسجيل الخروج بنجاح`, {
      position: "top-right",
      autoClose: 800,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      style: { direction: "rtl" },
    });
  }

  useEffect(() => {
    if (logout) {
      try {
        logoutDash();
        setLogout(false)
      } catch (error) {
        setLogout(false)
      }
    }
  }, [logout])

  const fundElete = async (id) => {
    await axios.delete(`http://ce.tuvglobalxavier.com/api/certificates/${id}`,{          headers: {
      Authorization: "bearer " + cookie.get("access_token"),
    },})
    setCertiList(certiList.filter(item => item.id !== id));
}

  useEffect(() => {
    if (deleteCerti) {
      try {
        fundElete(certiId);
        toast.success(`تم الحذف بنجاح`, {
          position: "top-right",
          autoClose: 800,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          style: { direction: "rtl" },
        });
        setDeleteCerti(false)
      } catch (error) {
        setDeleteCerti(false)
      }
    }
  }, [deleteCerti])

  useEffect(() => {
    const objectData = async () => {
      console.log(updateCerti)
      const result = await axios.put(
        `http://ce.tuvglobalxavier.com/api/certificates/${updateCerti.id}`,updateCerti,
        {
          headers: {
            Authorization: "bearer " + cookie.get("access_token"),
          },
        }
      ).then(response => response);

      const data = await result.data;
      console.log(data)
      // setCertiList([...certiList,data])
      toast.success(`تم التعديل بنجاح`, {
        position: "top-right",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        style: { direction: "rtl" },
      });
      setShowUpdate(false);
    };

    if (sendUpdateCert) {
      try {
        objectData();
        setSendUpdateCert(false)
      } catch (error) {
        setSendUpdateCert(false)
      }
    }
  }, [sendUpdateCert, updateCerti]);

  const createCertiHandler = (e) => {
    setCreateCerti({ ...createCerti, [e.target.name]: e.target.value });
  };
  const updateCertiHandler = (e) => {
    setUpdateCerti({ ...updateCerti, [e.target.name]: e.target.value });
  };
  const clickEdit = (item) => {
    setShowUpdate(true);
    setUpdateCerti(item)
  }

  const deleteHandler = (id) => {
    setCertiId(id);
    setDeleteCerti(true);
  }

  const submit = (e) => {
    e.preventDefault();
    setSendNewCert(true);
  };

  const submitUpdate = (e) => {
    e.preventDefault();
    setSendUpdateCert(true);
  }

  const data = certiList.map((item) => (
    <tr key={item.id}>
      <td className="p-2 border-solid border-[#ddd] border-b-2 border-r-2 border-l-2 text-center">
        {item.certificate_no}
      </td>
      <td className="p-2 border-solid border-[#ddd] border-b-2 border-r-2 text-center">
        {item.expire_date}
      </td>
      <td className="p-2 border-solid border-[#ddd] border-b-2 border-r-2 text-center">
        <div className=" flex gap-3 justify-center items-center">
          <div className=" flex gap-2 items-center bg-[#dc3545] cursor-pointer px-2 py-1 rounded-sm text-white" onClick={() =>
              Swal.fire({
                title: " هل أنت متأكد!",
                text: "في حالة الحذف لن تتمكن من العودة إلي هذا.",
                imageUrl: "/trash.svg",
                imageWidth: 100,
                showCancelButton: true,
                confirmButtonColor: "#D9003F",
                cancelButtonColor: "#4A5CB6",
                confirmButtonText: "نعم",
                cancelButtonText: "لا",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteHandler(item.id)
                }
              })
            }>
          <FontAwesomeIcon
            icon={faTrashCan}
          />
          <p>Delete</p>
          </div>
        </div>
      </td>
    </tr>
  ));
  return (
    <div className="flex flex-col gap-y-2 relative">
      {show && (
        <div className=" absolute top-0 left-0 w-screen h-screen z-50 bg-[#eeeeee99] flex justify-center items-center">
          <div className=" w-80 bg-white p-2 rounded-sm flex flex-col gap-4">
            <div className=" flex justify-between items-center py-3">
              <p className=" text-xl font-bold">Add Certificate</p>
              <FontAwesomeIcon
                icon={faXmark}
                className=" cursor-pointer"
                onClick={() => setShow(false)}
              />
            </div>
            <div>
              <form
                className=" flex flex-col gap-3"
                onSubmit={(e) => submit(e)}
              >
                <label>
                  <p>Certification No</p>
                  <input
                    type="text"
                    name="certificate_no"
                    placeholder="0000000000"
                    className=" w-full bg-[#eee] outline-none py-1 px-2 border border-[#eee] rounded-sm"
                    onChange={(e) => createCertiHandler(e)}
                  />
                </label>
                <label>
                  <p>Expire Date</p>
                  <input
                    type="date"
                    name="expire_date"
                    className=" w-full bg-[#eee] outline-none py-1 px-2 border border-[#eee] rounded-sm"
                    onChange={(e) => createCertiHandler(e)}
                  />
                </label>
                <div className=" bg-[#eee] py-3 flex justify-center gap-6">
                  <button
                    className=" cursor-pointer"
                    onClick={() => setShow(false)}
                  >
                    <p>Cancel</p>
                  </button>
                  <input
                    type="submit"
                    className=" bg-[#28a745] text-white py-1 px-6 rounded-sm cursor-pointer"
                    value="Add"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {
        showUpdate && 
        <div className=" absolute top-0 left-0 w-screen h-screen z-50 bg-[#eeeeee99] flex justify-center items-center">
          <div className=" w-80 bg-white p-2 rounded-sm flex flex-col gap-4">
            <div className=" flex justify-between items-center py-3">
              <p className=" text-xl font-bold">Update Certificate</p>
              <FontAwesomeIcon
                icon={faXmark}
                className=" cursor-pointer"
                onClick={() => setShowUpdate(false)}
              />
            </div>
            <div>
              <form
                className=" flex flex-col gap-3"
                onSubmit={(e) => submitUpdate(e)}
              >
                <label>
                  <p>Certification No</p>
                  <input
                    type="number"
                    name="certificate_no"
                    value={updateCerti.certificate_no}
                    placeholder="0000000000"
                    className=" w-full bg-[#eee] outline-none py-1 px-2 border border-[#eee] rounded-sm"
                    onChange={(e) => updateCertiHandler(e)}
                  />
                </label>
                <label>
                  <p>Expire Date</p>
                  <input
                    type="date"
                    name="expire_date"
                    value={updateCerti.expire_date}
                    className=" w-full bg-[#eee] outline-none py-1 px-2 border border-[#eee] rounded-sm"
                    onChange={(e) => updateCertiHandler(e)}
                  />
                </label>
                <div className=" bg-[#eee] py-3 flex justify-center gap-6">
                  <button
                    className=" cursor-pointer"
                    onClick={() => setShowUpdate(false)}
                  >
                    <p>Cancel</p>
                  </button>
                  <input
                    type="submit"
                    className=" bg-[#28a745] text-white py-1 px-6 rounded-sm cursor-pointer"
                    value="Update"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      }
      <div className="container mx-auto p-2 flex justify-between">
        <button
          className=" bg-[#28a745] py-1 px-3 text-white cursor-pointer rounded-sm flex gap-1 items-center"
          onClick={() => setShow(true)}
        >
          <FontAwesomeIcon icon={faPlus} className=" text-white" />
          <p>Add New Certificate</p>
        </button>
        <button
          className=" bg-[#dc3545] py-1 px-3 text-white cursor-pointer rounded-sm flex gap-1 items-center"
          onClick={() =>
              Swal.fire({
                title: " هل أنت متأكد من تسجيل الخروج!",
                iconHtml: "?",
                showCancelButton: true,
                confirmButtonColor: "#D9003F",
                cancelButtonColor: "#4A5CB6",
                confirmButtonText: "نعم",
                cancelButtonText: "لا",
              }).then((result) => {
                if (result.isConfirmed) {
                  setLogout(true)
                }
              })
            }
        >
          <FontAwesomeIcon icon={faArrowRightFromBracket} className=" text-white" />
          <p>Log out</p>
        </button>
      </div>
      <div className="container mx-auto h-min-h-64 overflow-scroll px-2">
        <table className=" min-w-[400px] w-full">
          <thead className="  bg-[#3cc0e5] border-[#3cc0e5] border-r-2 border-l-2">
            <tr>
              <th className="p-2 text-lg font-bold text-center whitespace-nowrap">
                Certificate No
              </th>
              <th className="p-2 text-lg font-bold text-center whitespace-nowrap">
                Expire date
              </th>
              <th className="p-2 text-lg font-bold text-center whitespace-nowrap">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>{data}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Certifcate;
